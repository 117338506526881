import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import React from "react";
import Routes from "./routers";
import { useLocation } from "react-router-dom";

function App() {
  const urlPath = useLocation().pathname;
  
  return(
    <>
      <Routes />     
      <ToastContainer 
          position="top-right"
          autoClose={5000}
          theme="light"
        />
    </>
  )
}

export default App;
