import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button} from "react-bootstrap";
import { 
    AppointmentStyled,
    ButtonDivStyled,
    IconStyled, 
    InfoStyled,
    TextStyled, 
    TitleStyled 
} from './styles'
import { useParams } from "react-router-dom";
import api from "../../utils/api";

import moment from 'moment';
import 'moment/locale/pt-br'
import Swal from 'sweetalert2'
import { toast } from "react-toastify";

import SuccessSvg from '../../assets/icons/success.svg'
import ErrorSvg from '../../assets/icons/error.svg'

const Appointment = () => {
    const { appointmentId } = useParams();

    const [appointment, setAppointment] = useState()

    useEffect(() => {
        getAppointmentDetails()
    }, [appointmentId]);

    const getAppointmentDetails = () => {
        api.getAppointmentDetails(appointmentId)
        .then(async res => {
            if (res.status === 200) {
                setAppointment(res.data)
            }
        })
        .catch(err => {
            console.log(err)      
        })
    }

    const confirmAppointment = () => {
        Swal.fire({
            title: 'Confirmar agendamento!',
            text: `Você deseja realmente confirmar este agendamento?`,
            icon: 'success',
            confirmButtonText: 'Confirmar agendamento',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        }).then(res => {
            if(res.isConfirmed) updateAppointmentStatus('CONFIRMED_BY_PATIENT')
        })
    }

    const cancelAppointment = () => {
        Swal.fire({
            title: 'Cancelar agendamento!',
            text: `Você deseja realmente cancelar este agendamento?`,
            icon: 'error',
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        }).then(res => {
            if(res.isConfirmed) updateAppointmentStatus('CANCELLED_BY_PATIENT')
        })
    }

    const updateAppointmentStatus = (status) => {
        api.updateAppointmentStatus({
            id: appointment.id,
            status: status        
        })
        .then(() =>{
            toast.success(`Agendamento ${status === 'CONFIRMED_BY_PATIENT' ? 'confirmado' : 'cancelado' } com sucesso!`)
            getAppointmentDetails()
        })
        .catch(err => {
            console.log(err)      
        })
    }

    const getStatusName = () => {
        switch(appointment?.status) {
            case 'ATTEND':
                return 'Compareceu';
            case 'SCHEDULED':
                return 'Sem situação';
            case 'CONFIRMED':
                return 'Confirmado';
            case 'CONFIRMED_BY_PATIENT':
                return 'Confirmado pelo paciente';
            case 'FINISHED':
                return 'Finalizado';
            case 'MISSED':
                return 'Faltou';
            case 'CANCELLED':
                return 'Cancelado';
            case 'CANCELLED_BY_PATIENT':
                return 'Cancelado pelo paciente';
            case 'WAITING':
                return 'Aguardando atendimento';
            case 'HAPPENING':
                return 'Em atendimento médico';
            case 'EXAMINATING':
                return 'Em exames';
            case 'DILATING':
                return 'Dilatando';
            case 'IN_PRE_CONSULTATION':
                return 'Em pré-consulta';
            case 'PRE_FINISHED':
                return 'Pré-consulta finalizada';
            default:
                return appointment?.status;
        }        
    }

    const getProfessionalName = () => {
        if (appointment?.professional) {
            return (<span>, com Dr.(a) {appointment?.professional?.name}.</span>)
        }
        return '.'
    }

    const getClinicName = () => {
        if (appointment?.clinic) {
            return (<span> em {appointment?.clinic?.name}</span>)
        }
        return null
    }
    const getClinicAddress = () => {
        if (appointment?.clinic?.address && !appointment?.telemedicine) {
            const address = appointment.clinic.address
            return (
                <span>
                    <span>No seguinte endereço:</span>
                    <span> {address.address}</span>
                    <span> - {address.number}</span>                
                    <span>, CEP: {address.zipcode}</span>                    
                    <span>, {address.neighborhood}</span>        
                    <span> - {address.city}.</span>
                </span>
            )
        }
        return null
    }

    const getClinicContact = () => {
        if (appointment?.clinic && !appointment?.telemedicine) {
          return (
            <span>
              Contato:
              <span>
                {appointment?.clinic?.telephone +
                  (appointment?.clinic?.telephone_2
                    ? " / " + appointment?.clinic?.telephone_2
                    : "") +
                  (appointment?.clinic?.telephone_3
                    ? " / " + appointment?.clinic?.telephone_3
                    : "")}{" "}
                <br />
                {appointment?.clinic?.address?.city} - {appointment?.clinic?.address?.state}
              </span>
            </span>
          );
        }
        return null;
      };


    return (
        <AppointmentStyled>
            <Helmet>
                <title>{`Agendamento ${appointment?.clinic?.name}`}</title>
                <meta name="description" content="Confirmação de agendamento" />
            </Helmet>
            {
                ['CONFIRMED', 'CONFIRMED_BY_PATIENT'].includes(appointment?.status) &&
                <>
                    <IconStyled success>
                        <img
                            src={SuccessSvg}
                            width="50px"
                            height="50px"
                            alt="success svg"
                        /> 
                    </IconStyled>

                    
                    <TitleStyled className="mt-3">
                        Agendamento confirmado
                    </TitleStyled>

                    <TextStyled> 
                        {appointment?.patient?.name}, seu agendamento está confirmado para {' '}
                        {moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('dddd')} no dia <b>{moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('DD')} de {' '}
                        {moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('MMMM')} às {' '} 
                        {moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('HH')}h{moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('mm')}</b> de {' '}
                        {moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('YYYY')} 
                        {getClinicName()}.
                    </TextStyled>
                </>
            }

            {
                ['CANCELLED', 'CANCELLED_BY_PATIENT'].includes(appointment?.status) &&
                <>
                    <IconStyled error>
                        <img
                            src={ErrorSvg}
                            width="50px"
                            height="50px"
                            alt="success svg"
                        /> 
                    </IconStyled>

                    <TitleStyled className="mt-3">
                        Agendamento cancelado
                    </TitleStyled>

                    <TextStyled> 
                        {appointment?.patient?.name}, seu agendamento para {' '}
                        {moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('dddd')} no dia <b>{moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('DD')} de {' '}
                        {moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('MMMM')} às {' '} 
                        {moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('HH')}h{moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('mm')}</b> de {' '}
                        {moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('YYYY')} {getClinicName()} foi <b>cancelado.</b>
                    </TextStyled>
                </>
            }

            {
                !['CANCELLED', 'CANCELLED_BY_PATIENT', 'CONFIRMED', 'CONFIRMED_BY_PATIENT'].includes(appointment?.status) &&
                <>
                    <TitleStyled>
                        Olá {appointment?.patient?.name},
                    </TitleStyled>
                
                    <TextStyled>
                        Você possui um agendamento marcado para {' '}
                        {moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('dddd')} no dia <b>{moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('DD')} de {' '}
                        {moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('MMMM')} às {' '} 
                        {moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('HH')}h{moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('mm')}</b> de {' '}
                        {moment(appointment?.start_datetime, 'YYYY-MM-DD HH:mm:ss').locale('pt-br').format('YYYY')} 
                        {getClinicName()}
                        {getProfessionalName()}
                    </TextStyled>
                    <TextStyled>
                        {getClinicAddress()}
                    </TextStyled>
                    {
                        appointment?.status === 'SCHEDULED' ?
                            <TextStyled> Clique abaixo para confirmar ou cancelar o seu agendamento. </TextStyled>
                        :
                            <TextStyled> O status atual do agendamento é: {getStatusName()}</TextStyled>
                    }
                </>
            }

            {
                ['CANCELLED', 'CANCELLED_BY_PATIENT'].includes(appointment?.status) 
                ?
                    <TextStyled> 
                        Entre em contato para reagendar.
                    </TextStyled>
                :
                    ['CONFIRMED', 'CONFIRMED_BY_PATIENT', 'SCHEDULED'].includes(appointment?.status) &&                    
                        <ButtonDivStyled>
                            {
                                !['CONFIRMED', 'CONFIRMED_BY_PATIENT'].includes(appointment?.status) &&                     
                                <Button 
                                    variant="primary"
                                    onClick={() => confirmAppointment()}
                                >
                                    Confirmar agendamento
                                </Button>
                            }

                            <Button 
                                variant="outline-danger"
                                onClick={() => cancelAppointment()}
                            >
                                Cancelar agendamento
                            </Button>
                        </ButtonDivStyled>
            }
            
            <TextStyled> Qualquer dúvida, estamos a disposição</TextStyled>

            <InfoStyled>
                {appointment?.clinic?.name} <br/>
                {getClinicContact()}
            </InfoStyled>          
        </AppointmentStyled>
    )
}

export default Appointment;
