import { ErrorStyled } from "./styles";

const Error = ({message}) => {    
    return (
        <ErrorStyled>  
            {message}
        </ErrorStyled>
    )
}

export default Error;