import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Row } from "react-bootstrap";
import { 
    CardStyled, 
    ClinicStyled, 
    RowMapStyled, 
    SubtitleStyled, 
    TextStyled, 
    HeaderStyled,
    ClinicLogoStyled,
    TitleStyled 
} from './styles'
import { useParams } from "react-router-dom";
import api from "../../utils/api";

import Calendar from "../../components/Schedule/Calendar";
import LocationSvg from '../../assets/icons/location.svg'
import PhoneSvg from '../../assets/icons/phone.svg'
import EmailSvg from '../../assets/icons/email.svg'

const ClinicSchedule = () => {
    const { clinicId } = useParams();

    const [clinic, setClinic] = useState()
    const [brandUrl, setBrandUrl] = useState()
    const [professionals, setProfessionals] = useState()

    useEffect(() => {
        getPublicSchedule()
    }, [clinicId]);

    const getPublicSchedule = () => {
        api.getPublicScheduleByClinicId(clinicId)
        .then(async res => {
            if (res.status === 200) {
                setClinic(res.data)
                getPicTemporaryUrl(res.data.clinic.brand)
                getPublicScheduleProfessionals()
            }

        })
        .catch(err => {
            console.log(err)      
        })
    }

    const getPublicScheduleProfessionals = () => {
        api.getPublicScheduleProfessionalsByClinicId(clinicId, 'public-schedule')
        .then(async res => {
            if (res.status === 200) {
                setProfessionals(res.data)
            }
        })
        .catch(err => {
            console.log(err)      
        })
    }

    const getPicTemporaryUrl = (pic) => {
        api.getDocTemporaryUrl(`public/images/clinics/${pic}`)
        .then(async res => {
            setBrandUrl(res.data)        
        })
        .catch(err => {
            console.log(err)      
        })
    }

    return (
        <ClinicStyled>
            <Helmet>
                <title>{`Agendamento ${clinic?.clinic?.name}`}</title>
                <meta name="description" content="Agendamento online" />
            </Helmet>
            <HeaderStyled>
                {brandUrl && (
                    <ClinicLogoStyled
                        src={brandUrl}
                        alt="Logo da clínica"
                    /> 
                )}
                <TitleStyled>
                    Agendamento {clinic?.clinic?.name}
                </TitleStyled>
            </HeaderStyled>

            <hr className="mt-5 mb-5"/>
            
            <Row>
                <SubtitleStyled>Agendar Horário</SubtitleStyled>
                {
                    clinic?.id && clinic?.clinic_id && 
                    <Calendar 
                        professionals={professionals}
                        publicScheduleId={clinic?.id ?? ''}
                        clinicId={clinic?.clinic_id ?? ''}
                    />
                }
            </Row>   

            <hr className="mt-5 mb-5"/>

            <RowMapStyled>
                <Col id="map">
                    <Row>
                        <Col xs="12">
                            <CardStyled>
                                <div className="d-flex align-items-center">
                                    <span>                            
                                        <img
                                            src={LocationSvg}
                                            width="17px"
                                            height="24px"
                                            alt="location svg"
                                        />
                                    </span>
                                    {clinic && clinic.address ? `${clinic.address.address}, ${clinic.address.number}` + (clinic.address.complement ? ', ' + clinic.address.complement : '') + ` - ${clinic.address.neighborhood}, ${clinic.address.city} - ${clinic.address.state}, ${clinic.address.zipCode} ` : ''}
                                </div>
                            </CardStyled>
                        </Col>

                        <Col xs="12">
                            <CardStyled>
                                <div className="d-flex align-items-center">
                                    <span>                            
                                        <img
                                            src={PhoneSvg}
                                            width="17px"
                                            height="24px"
                                            alt="phone svg"
                                        />
                                    </span>
                                    {clinic ? clinic.phone + '' + (clinic.cellphone ? ', ' + clinic.cellphone : '') : ''}
                                </div>
                            </CardStyled>
                        </Col>

                        <Col xs="12">
                            <CardStyled>
                                <div className="d-flex align-items-center">
                                    <span>                                
                                        <img
                                            src={EmailSvg}
                                            width="17px"
                                            height="24px"
                                            alt="email svg"
                                        /> 
                                    </span>
                                    {clinic ? clinic.email + '' + (clinic.email2 ? ', ' + clinic.email2 : '') : ''}
                                </div>
                            </CardStyled>
                        </Col>
                    </Row>               
                </Col>
            </RowMapStyled>    
        </ClinicStyled>
    )
}

export default ClinicSchedule;

