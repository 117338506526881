import {Form} from 'react-bootstrap';
import { InputMaskStyled, LabelStyled } from './styles'
import Error from './error';

const InputMasked = ({label, mask, fieldValue, setValue, readonly, placeholder, error, activeError, onBlur}) => {    
    return (
        <div>            
            <Form.Group>  
                <LabelStyled>{label}</LabelStyled>
                <InputMaskStyled 
                    className='form-control pt-1'
                    readOnly={readonly}
                    mask={mask} 
                    placeholder={placeholder}
                    value={fieldValue} 
                    onChange={(value) => setValue(value.target.value)}
                    onBlur={onBlur}
                    error={error}
                />
                {
                    activeError &&                         
                    <Error message={error === 'erro' ? '' : error} />
                }
            </Form.Group>
        </div>
    )
}

export default InputMasked;