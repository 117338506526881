import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { 
    AppointmentStyled,
    DocStyled,
    SubtitileStyled,
    TextStyled, 
    TitleStyled
} from './styles'
import { useParams } from "react-router-dom";
import api from "../../../utils/api";

import moment from 'moment';
import 'moment/locale/pt-br'
import DocSvg from '../../../assets/icons/doc.svg'


const AttendanceFiles = () => {
    const { attendanceId } = useParams();
    const notTypes = ['0']
    const [attendance, setAttendance] = useState()
    const [attendanceFiles, setAttendanceFiles] = useState()

    useEffect(() => {
        getAttendance()
        getAttendanceFiles()
    }, [attendanceId]);

    const getAttendance = () => {
        api.getAttendance(attendanceId)
        .then(async res => {
            if (res.status === 200) {
                setAttendance(res.data)
            }
        })
        .catch(err => {
            console.log(err)      
        })
    }

    const getAttendanceFiles = () => {
        api.getAttendanceFiles(attendanceId, true, notTypes)
        .then(async res => {
            if (res.status === 200) {
                setAttendanceFiles(res.data)
            }
        })
        .catch(err => {
            console.log(err)      
        })
    }

    const openFile = (url) => {
        console.log(url)
        window.open(url);
    }

    return (
        <AppointmentStyled>
            <Helmet>
                <title>{`Consulta ${attendance?.professional?.name}`}</title>
                <meta name="description" content="Confirmação de agendamento" />
            </Helmet>

            
            <TitleStyled className="mt-3">
                Olá, {attendance?.patient?.name}, seja bem-vindo(a)!
            </TitleStyled>

            <TextStyled> 
                Abaixo você terá acesso aos documentos emitidos referentes a sua última consulta.
                <br />
                {attendance?.professional?.name}, no dia <span className="date"> {moment(attendance?.start_datetime).format('DD')} de {moment(attendance?.start_datetime).format('MMMM')} de {moment(attendance?.start_datetime).format('Y')} </span>
            </TextStyled>

            <SubtitileStyled>
                Clique para baixar e visualizar seus documentos
            </SubtitileStyled>
            
            {attendanceFiles?.map((file) => {
                return (
                    <DocStyled
                        onClick={() => {openFile(file.temporaryUrl)}}
                    >
                        <img
                            src={DocSvg}
                            width="50px"
                            height="50px"
                            alt="success svg"
                        /> 
                        <span>                            
                            {file.custom_name}
                        </span>
                    </DocStyled>
                );
            })}

            <TextStyled className="mt-5"> 
                <div className="mb-3">Se precisar, estaremos aqui por você! 💙</div>
                {
                    attendance?.clinic?.type === 'BV' ?
                        'Equipe Benefício Visão' :
                        'Equipe EyecareBI'
                }
            </TextStyled>
        </AppointmentStyled>
    )
}

export default AttendanceFiles;