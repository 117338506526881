import styled from 'styled-components';
import { Card , Col, Row} from 'react-bootstrap';

export const CardStyled = styled(Card) `
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    padding: 1.5rem 1.8rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #1A1E2B;
    margin-bottom: 15px;
    border: none;

    span {
        padding: 11px 15px;
        border: 0;
        background: #E7ECFE;
        border-radius: 50%;
        margin-right: 1.5rem;

    }

    @media (max-width: 769px) {
        padding: 1rem;

        span {
            margin-right: .5rem;
        }
    }
`;

export const ClinicStyled = styled.div`
    width: 98vw;
    overflow: hidden !important;

    @media (max-width: 719px) {
        padding: 1.5rem;
    }

    @media (min-width: 720px) {
        padding: 4rem;
        overflow-x: hidden !important;
    }
`;

export const RowMapStyled = styled(Row)`
    #map{
        width: 100%;
        height: 50vh;

        @media (max-width: 769px) {
            margin: 0 0 35px;
        }
    }

    #infos{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
`;

export const SubtitleStyled = styled.h3`
    margin-top: 15px;
    margin-bottom: 25px;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: max(18px, 1.5vw);
    line-height: 126%;
    letter-spacing: -0.01em;
    color: #305BF2;
`;

export const HeaderStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const ClinicLogoStyled = styled.img`
    width: 100px;
    border-radius: 8px;
`;

export const TextStyled = styled.p`
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 125%;
    color: #525C7A;
`;

export const TitleStyled = styled.div`
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: max(16px, 2vw);
    color: #0C1D59;
    
    @media (max-width: 769px) {
        font-size: max(18px, 2vw);
    }
`;