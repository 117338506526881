import styled from 'styled-components';

export const AppointmentStyled = styled.div`
    @media (max-width: 719px) {
        padding: 50px 10px 40px 10px;
        overflow-x: hidden;
    }

    @media (min-width: 720px) {
        padding: 50px 150px 40px 150px;
    }
`;

export const DocStyled = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #525C7A;    
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    margin-bottom: 15px;

    :hover{
        color: #305BF2;
    }
`;

export const SubtitileStyled = styled.h4`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #525C7A;
    margin: 30px 0 22px 0;
`;

export const TextStyled = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #525C7A;

    .date{
        color: #305BF2;
    }
`;

export const TitleStyled = styled.h2`
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 132%;
    color: #0C1D59;
    margin-bottom: 25px;
`;