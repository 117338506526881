import styled from 'styled-components';


export const ContainerStyled = styled.div`
    width: 100%;
    height: 100vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
`;
