import {Form} from 'react-bootstrap';
import { FormControlStyled, LabelStyled } from './styles'
import Error from './error'

const Input = ({label, subLabel, type, placeholder, fieldValue, setValue, readonly, required, error, activeError}) => {
    return (
        <div>            
            <Form.Group>
                {
                    label &&                    
                    <LabelStyled>
                        {label}                    
                        {
                            subLabel &&                        
                            <small>{subLabel}</small>
                        } 
                    </LabelStyled>
                }
                <FormControlStyled 
                    required={required}
                    readOnly={readonly}
                    type={type} 
                    placeholder={placeholder} 
                    value={fieldValue}
                    onChange={(value) => !readonly ? setValue(value.target.value) : ''}
                    error={error}
                />
                {
                    activeError &&                 
                    <Error message={error === 'erro' ? '' : error} />
                }
            </Form.Group>
        </div>
    )
}

export default Input;