import {Form} from 'react-bootstrap';
import { FormControlStyled, LabelStyled, ImgStyled } from './styles'
import Error from './error'

import ChevronDown from '../../assets/icons/chevron-down.svg'

const Select = ({label, subLabel, options, noOption, value, setValue, error, activeError}) => {

    return (
        <div>            
            <Form.Group>
                <LabelStyled>
                    {label}
                    {
                        subLabel &&                        
                        <small>{subLabel}</small>
                    }                
                </LabelStyled>
                <div className='d-flex inline position-relative'> 
                    <FormControlStyled
                        className="form-control pt-1"
                        as="select"
                        value={value}
                        onChange={e => {
                            setValue(e.target.value);
                        }}
                    >
                        {
                            noOption &&
                            <option value="">Selecionar</option>
                        }
                        {
                            options && options.map((opt, index) => {
                                return <option key={index} value={opt.value}>{opt.label}</option>
                            })
                        }
                    </FormControlStyled>
                    
                    <ImgStyled
                        src={ChevronDown}
                        width="17px"
                        height="24px"
                        alt="svg"
                    />
                </div>
                
                {
                    activeError &&                 
                    <Error message={error === 'erro' ? '' : error} />
                }
            </Form.Group>
        </div>
    )
}

export default Select;