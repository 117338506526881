import styled from 'styled-components';
import {Modal} from 'react-bootstrap';

export const ModalTitleStyled = styled(Modal.Title) `
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #525C7A;
`;