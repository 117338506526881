import React from "react";
import { Routes, Route } from "react-router-dom";

import NotFoundPage from "./pages/NotFoundPage";
import ClinicSchedule from "./pages/ClinicSchedule";
import Appointment from "./pages/Appointment";
import AttendanceFiles from "./pages/Attendance/AttendanceFiles";

const Routers = function() {

    return (
        <Routes forceRefresh={false}>                        
            <Route path="/clinic/:clinicId" element={<ClinicSchedule />} />   
            <Route path="/appointment/:appointmentId" element={<Appointment />} />   
            <Route path="/attendance/:attendanceId/files" element={<AttendanceFiles />} />   

            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    )
    
}

export default Routers;