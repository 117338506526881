import React from 'react';
import { ContainerStyled } from './styles';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const NotFoundPage = function() {
    const navigate = useNavigate()

    return (
        <ContainerStyled>
            <Button onClick={() => navigate(-1)}>
                {"< Voltar"}
            </Button>

            Pagina não encontrada / ou você não possui permissão para acessá-la
        </ContainerStyled>
    )
}


export default NotFoundPage;