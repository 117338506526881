import styled from 'styled-components';
import {Card, Col, Row} from 'react-bootstrap';

export const ColStyled = styled(Col) `
    display:flex;
    justify-content: center;
    padding: 24px;

    Button{
        padding: 10px 100px;
    }
`;

export const IntervalRowStyled = styled(Row) `
    display:flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
`;

export const NoAppointmentsStyled = styled.h5`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14.5px !important;
    line-height: 150%;
    color: #1A1E2B;
    margin-bottom: 13px;
    text-align: center;
    width: full;
`;

export const ProfessionalCardStyled = styled(Card) `
    margin-top: 15px;
    background: #F4F5FB;
    border-radius: 8px;
    padding: 24px;
    width: 100%;
    border: none;
`;

export const ProfessionalCardTextStyled = styled(Card.Text) `    
    margin-top: -10px;
    max-width: 100%;
    width: 100%;

    h4{
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px
        line-height: 150%;
        color: #0C1D59;
        margin-bottom: 13px;
    }

    div{        
        display: flex;     
        justify-content: flex-start;
        flex-wrap: wrap;
    }
`;

export const ProfessionalCardTitleStyled = styled(Card.Title) `
    display: flex;
    column-gap: .9rem;
    margin-bottom: -5px;

    img{
        @media (max-width: 426px) {
            display: none;
        }
    }

    span{
        display: flex;
        flex-direction: column;
        justify-content: center; 
        font-family: 'Inter';
        font-style: normal;
        color: #1A1E2B;

        h2{            
            font-weight: 800;
            font-size: max(18px, 1.5vw);
        }

        p{
            font-weight: 500;
            font-size: max(14px, 1.08vw);
            margin: 0;
        }
    }
`;

export const InfoStyled = styled.img `
    margin-left: 5px;
`;

export const SpanStyled = styled.span `
    margin-bottom: 10px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: ${props => props.selected ? '#FFFFFF' : '#304388'};
    margin-right: 10px;
    padding: 8px 16px;    
    background: ${props => props.selected ? '#304388' : '#FFFFFF'};
    border: 1px solid #305BF2;
    border-radius: 8px;
    cursor: pointer;
    text-aligm: center;

    :hover{
        background: #305BF2;
        color: #FFFFFF;
    }
`;