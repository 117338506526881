import styled from 'styled-components';

export const AppointmentStyled = styled.div`
    @media (max-width: 719px) {
        padding: 50px 10px 40px 10px;
        overflow-x: hidden;
    }

    @media (min-width: 720px) {
        padding: 50px 150px 40px 150px;
    }
`;

export const ButtonDivStyled = styled.div`
    display: flex;
    padding: 20px 0px;
    gap: 15px;

    @media (max-width: 719px) {
        flex-direction: column;
    }
`;

export const IconStyled = styled.span`
    padding: 20px 10px 25px 10px;
    border-radius: 25px;
    background: ${props => props.success ? '#DBFFF0' : (props.error ? '#FFD1CD' : '')};
`;

export const InfoStyled = styled.div`
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 150%;
    color: #525C7A;
`;

export const TextStyled = styled.p`
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #525C7A;
`;

export const TitleStyled = styled.h2`
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 132%;
    color: #0C1D59;
    margin-bottom: 25px;
`;