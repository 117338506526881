import styled from 'styled-components';
import {Card, Nav} from 'react-bootstrap';

export const CalendarStyled = styled.div `
    h4{
        margin-top: 10px;
        margin-bottom: 10px;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #0C1D59;
    }
`;

export const HeaderCardStyled = styled(Card) `
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #0C1D59;
    border-radius: 8px;
    color: #FFFFFF;
    padding: 16px 0px;
    gap: 10px;

    @media (min-width: 769px) {
        padding: 1rem 1.5rem;
        flex-direction: row;
    }

    @media (max-width: 769px) {
        flex-direction: column;
        row-gap: 1.5rem;
    }
`;

export const InlineStyled = styled.span `
    display: flex;
    
    span{
        text-transform: uppercase;
    }
`;

export const NavLinkStyled = styled(Nav.Link) `
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: max(12px, 1vw);

    @media (max-width: 769px) {
        padding: .5rem;
    }
`;

export const NavStyled = styled(Nav) `
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;

    @media (max-width: 486px) {
        flex-wrap: nowrap;
    }
    @media (max-width: 769px) {
        width: 100%;
        padding: 0 .5rem;
    }
`;

export const NavItemStyled = styled(Nav.Item) `
    display: flex;
    align-items: center;
    padding: 0px;

    &:last-child {
        justify-content: flex-end;
    }

    img{
        cursor: pointer;
        width: .8rem;

        @media (max-width: 769px) {
            width: .6rem;
        }
    }
`;



export const ProfessionalCardStyled = styled(Card) `
    margin-top: 15px;
    background: #F4F5FB;
    border-radius: 8px;
    padding: 24px;
`;

export const ProfessionalCardTextStyled = styled(Card.Text) `    
    h4{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #1A1E2B;
        margin-bottom: 13px;
    }

    div {
        span{    
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: #304388;
            margin-right: 10px;
            padding: 8px 16px;    
            background: #FFFFFF;
            border: 1px solid #305BF2;
            border-radius: 8px;
            cursor: pointer;

            :hover{
                background: #305BF2;
                color: #FFFFFF;
            }
        }
    }
`;

export const ProfessionalCardTitleStyled = styled(Card.Title) `
    display: flex;
    margin-bottom: -14px;
    span{        
        margin-left: 12px;        
        font-family: 'Inter';
        font-style: normal;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1A1E2B;

        h2{            
            font-weight: 800;
            font-size: 30px;
        }

        p{
            font-weight: 500;
            font-size: 18px;
        }
    }
`;

export const SpanStyled = styled.span `
    display: flex;
    align-items: center;
    width: 45%;

    span{
        margin-left: 12px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: max(14px, 1vw);
        line-height: 150%;
        text-decoration-line: underline;
        cursor: pointer;

        :hover{
            text-decoration-line: none;
        }
    }

    @media (max-width: 769px) {
        width: 100%;
        justify-content: center;
    }
`;

export const TextCardStyled = styled(Card) `
    text-align: center;
    border: 0;
    padding: 40px 0px;
`;


